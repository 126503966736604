@tailwind base;

@tailwind components;

@tailwind utilities;

button:focus,
input:focus {
  @apply outline-none;
}

.subtitle {
  margin-top: -2%;
}
.circle {
  list-style-type: circle;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.step {
  border-radius: 1px;
  width: 600px;
  height: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.primary-btn {
  background-color: #ffc600;
  color: #292929;
  border-radius: 9999px;
}

.secondary-btn {
  background-color: transparent;
  color: rgb(251, 199, 1);
  box-sizing: border-box;
  border-color: rgb(251, 199, 1);
  border-width: 2px;
  border-radius: 9999px;
}

.latest-news-btn {
  background-color: transparent;
  color: rgb(251, 199, 1);
  box-sizing: border-box;
  border-color: rgb(251, 199, 1);
  border-width: 2px;
  border-radius: 20px;
}

.mobile-menu-container .primary-color {
  color: #ffc600;
  border-color: #ffc600;
}

.subscribe-btn.disabled {
  @apply bg-gray-400 cursor-not-allowed;
}

.subscribe-btn.enabled {
  background-color: #dd2673;
}

.check-mark {
  color: #dd2673;
}

.my-swipper {
  height: 100%;
}
.my-swipper .swiper-pagination-bullet {
  background: gray;
  opacity: 0.75;
}
.my-swipper .swiper-pagination-bullet-active {
  background-color: white;
}

#highlights-container {
  height: 100%;
}
#highlights-container a {
  height: 100%;
}
#highlights-container a img {
  height: 100%;
}

.faq .question {
  color: #374151;
  position: relative;
  background: #f0f3f0;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  border-top: 1px solid #00050c;
  cursor: pointer;
}
.faq .answers {
  color: #374151;
  height: 0;
  background: #f7f8ef;
  overflow: hidden;
  margin: 0 0 7px 0;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  padding: 0px 8px;
}
.faq .plus {
  color: #ec4899;
  position: absolute;
  margin-left: 10px;
  margin-top: 3px;
  align-items: center;
  font-size: 2em;
  line-height: 100%;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.faq table {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  align-items: center;
}
.faq b {
  margin-top: 8px;
}
.faq p {
  color: #1f2937;
}
.faq td,
.faq th {
  border: 1px solid #fae8fc;
  border-collapse: collapse;
  width: 33%;
  padding: 15px;
  color: #374151;
}
.faq h3 {
  font-weight: bold;
}
.faq span {
  color: #0f6fc9;
}
.faq ol {
  margin-left: 10%;
  list-style-type: decimal;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.questions:checked ~ .answers {
  height: auto;
  opacity: 1;
  padding: 15px;
}

.questions {
  display: none;
}

@media only screen and (max-width: 768px) {
  .step {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .labtop {
    width: 105%;
  }
}

@media (max-width: 760px) and (min-height: 768px) {
  .labtop {
    bottom: 38%;
  }
}
